.r-gallery {
	@include list-unstyled();
	@include make-row();
  	margin: .5rem -.5rem;
}
.r-gallery-item {
	width: 50%;
	padding: 0 .5rem;
    margin: .5rem 0;
    @include media-breakpoint-up(sm) {
    	width: 33.33%;
	}
	@include media-breakpoint-up(lg) {
    	width: 25%;
	}
	img {
		transition: .3s transform ease;
		transform: scale(1.1);
		-webkit-backface-visibility: hidden;
	}
	.icon {
	    font-size: 1.5rem;
	    position: absolute;
	    top: 50%;
	    left: 50%;
	    transform: translateY(-50%) translateX(-50%);
	    color: #fff;
	    z-index: 1;
	    margin-top: 1rem;
	    opacity: 0;
	    visibility: hidden;
	    transition: .15s all ease-out;
	    @include media-breakpoint-up(md) {
	    	font-size: 2rem;
		}
	}


	a {
		position: relative;
		display: block;
		overflow: hidden;
		&:before {
			position: absolute;
			transition: .3s all ease;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			content: "";
			z-index: 1;
			height: 100%;
			background: rgba($black, .6);
			opacity: 0;
			visibility: hidden;
		}
		&:hover {
			img {
				transform: scale(1);
			}
			&:before {
				opacity: 1;
				visibility: visible;
			}
			.icon {
			    margin-top: 0;
			    opacity: 1;
			    visibility: visible;
			}
		}
	}
}

.ref-gallery {
    .slide {
        cursor: pointer;
        overflow: hidden;
        img {
            will-change: transform;
            transition: $transition-base;
        }
        &:hover {
            img {
                transform: scale(1.05);
            }
            .icons-media {
                transform: scale(1.05) translateX(-.5rem) translateY(-.5rem);
                background: rgba($primary, $alpha: .95);
            }
            .icon-play:after {
                transform: scale(1.25);
                opacity: 1;
            }
        }
        
        .icon-play:after {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url('../img/icons/play.svg');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 4rem;
            opacity: .75;
            transition: $transition-base;
        }
        .icons-media {
            will-change: transform;
            transition: $transition-base;
            position: absolute;
            bottom: .5rem;
            right: .5rem;
            padding: .25rem .5rem;
            border-radius: $border-radius;
            background: rgba($dark, $alpha: .75);
            color: $light;
            font-size: $font-size-sm;
            font-weight: $font-weight-bolder;
            span:nth-child(2) {
                margin-left: .25rem;
            }
        }
    }
}

.video .ratio {
    overflow: hidden;
    width: 100%;
}
