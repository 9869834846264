
.teasers {
    
    @include media-breakpoint-up(sm) {    
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
        gap: 2rem;
    }

    .teaser {
        &.card {
            &-img {
                background: $primary;
                &-overlay {
                    color: $white;
                    // top: auto;
                    padding: $card-spacer-x;
                    h3 {
                        color: $white;
                        position: relative;
                    }
                    p {
                        @include font-small();
                        color: $secondary;
                    }
                }
            }
        }
    }

    &.person-teasers {
        grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    }

    &.team-teasers {
        display: grid;
        grid-template-columns: 1fr 1fr;
    	gap: $card-columns-gap;
        @include media-breakpoint-up(md) {
			grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
		}
    }

    &.reference-teasers {
        grid-template-columns: 1fr;
        row-gap: 2rem;
        @include media-breakpoint-up(md) {
            .teaser:nth-of-type(even) {
                .ref-asset {
                    order: 13;
                }
            }
        }
    }
}

.team-teaser-link {
    .team-teaser .card-img-overlay {
        opacity: 1;
    }
}

.team-teaser, .download-teaser {
    .card-img {
        background: url(../img/icons/profile.svg) no-repeat center;
        background-color: $gray-200;
        background-size: 75%;
    }
    .team-img {
        @include media-breakpoint-down(sm) {
            margin-left: auto;
            margin-right: auto;
            max-width: 7.5rem;
        }

    }
    .card-img-overlay {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        background-color: $yellow;
        opacity: 0;
        @include transition(opacity .2s ease-in);
    }
    &:hover {
        .card-img-overlay {
            opacity: 1;
        }
    }
}