.products {
    .page-main {
        // margin: 0;
        padding-top: 0;
    }

    ul.info {
        display: flex;
        justify-content: space-between;
        @extend .list-unstyled;

        li {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 6rem;
            height: 8rem;

            &.info-surface {
                background: url(../img/icons/house.svg) 50% 50% no-repeat;
            }

            &.info-surface-apartment {
                background: url(../img/icons/apartment.svg) 50% 50% no-repeat;
            }

            &.info-persons {
                background: url(../img/icons/person.svg) 50% 50% no-repeat;
            }

            &.info-bathrooms {
                background: url(../img/icons/bathroom.svg) 50% 50% no-repeat;
                padding-top: 2.5rem;
            }
        }
    }
}

.slider-slide {
    position: relative;
    scroll-margin-top: 56px;

    &:first-of-type {
        .downArrow-wrapper {
            bottom: 3rem;
        }
    }

    @include media-breakpoint-down(sm) {
        padding: 2rem 0;
    }

    &.slide-dark {
        background-color: #1a1a1a;
        color: $white;
    }

    .slide-one--right {
        padding-left: 1rem;
        border-left: 2px solid $yellow;
        // content: "";
        // background: $yellow;
        // position: absolute;
        // bottom: 0;
        // left: 0;
        // height: 50%;
        // width: 2px;
    }

    // small hack to keep the content of the (first) slider in the middle of the container - the 56px is the height of the nav
    .slider-content {
        min-height: calc(100vh - 56px);

        .img-contain  {
            img {
                max-height: 75vh;
                max-width: 100%;
            }
        }


        @include media-breakpoint-down(md) {
            // min-height: unset;
            height: auto;
        }
    }
}

.bg-cover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: 0;

    figure,
    img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.slide-three {
    @include media-breakpoint-down(md) {
        height: 100%;
    }
}

.components {
    @include list-unstyled();
    height: 0;
    padding-top: 100%;
    position: relative;
    margin: 0;
    overflow: hidden;

    li.component {
        position: absolute;
        left: 50%;
        top: 50%;
        display: flex;

        @include media-breakpoint-down(md) {
            display: none;
        }
        .pinpoint {
            background: $yellow;
            color: $white;
            display: block;
            position: absolute;
            text-align: center;
            height: 1.75rem;
            width: 1.75rem;
            font-weight: 500;
            border-radius: 50%;
            border: 3px solid $yellow;
            text-indent: 0;
            z-index: 10;
            background-image: url(../img/icons/crosshair.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            cursor: crosshair;

            @include media-breakpoint-up(md) {
                animation: wiggle 2s infinite;
            }

            @include media-breakpoint-down(md) {
              display: none;
            }

            &:hover + span {
                opacity: 1;
            }
        }

        span {
            opacity: 0;
            position: absolute;
            color: $gray-800;
            line-height: 1;
            font-size: 1rem;
            padding: .5rem;
            border-radius: .25rem;
            background-color: $yellow;
            z-index: 100;
            margin-left: 3rem;
            font-weight: 500;
            transition: all 300ms ease;

            &:before {
                position: absolute;
                z-index: 0;
                top: calc(50% - 2px);
                content: " ";
                border-bottom: 2px solid $yellow;
                margin-left: -2rem;
                padding-left: 2rem;
            }
        }
    }
}

.slider-wrapper {
    position: relative;
}

.navbar-products-wrapper {
    width: 100%;
}

.navbar-products {
    z-index: 1;
    transition: .5s ease-in-out;
    margin: .9rem auto 0;

    @include media-breakpoint-down(md) {
        justify-content: left;
    }

    a {
        font-size: .9rem;
        color: rgba($white, .25);

        @include media-breakpoint-down(md) {
            padding: 0 .5rem;
        }

        &:hover {
            color: $white;
            text-decoration: none;
            transition: .5s ease-in-out;
        }

        &.active {
            color: $white;
            font-weight: 700;
            // padding: 0 .5rem .5rem;
            padding: .35rem .75rem;
            background-color: #a3a3a3;
            border-radius: 25px;
            @include media-breakpoint-down(md) {
                padding: 0;
                font-size: $font-size-sm;
            }
        }
    }
}

.slider-nav {
    display: inline-block;
    top: 50%;
    position: fixed;
    height: auto;
    margin: 0 auto;
    left: 1%;
    z-index: 100;
    mix-blend-mode: difference;


    .slider-nav-item {
        .bullet {

            a {
                // margin-bottom: .75rem;
                text-decoration: none;
                transition: .25s all ease-in-out;
                overflow: hidden;
                height: 100%;
                position: relative;

                span {
                    text-indent: -10000px;
                    color: rgba($white, 1);
                    transition: .25s color ease-in-out;
                    position: relative;
                }

                &:hover span {
                    text-indent: 0;
                    display: inline-block;
                    color: rgba($white, 1);
                    transition: .25s color ease-in-out;
                }

                &::before {
                    overflow: hidden;
                    margin-right: .75rem;
                    content: "";
                    background-color: rgba($white, 0);
                    border: 2px solid rgba($white, 0.5);
                    border-radius: 50%;
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    font-size: 0;
                    white-space: nowrap;
                    overflow: hidden;
                    outline: none;
                    box-sizing: border-box;
                    transition: .2s ease-in-out;
                    cursor: pointer;
                }

                &:hover {
                    color: rgba($white, .5);
                    transition: .25s all ease-in-out;
                    &::before {
                        border-color: rgba($white, .250);
                        background-color: rgba($white, .5);
                        transition: .25s all ease-in-out;
                    }
                }

            }

            &.active a::before {
                background-color: rgba($white, 1);
                border-color: rgba($white, 1);
            }

        }
    }

}

.scroll-nav {
    z-index: 100;
    position: absolute;


    &-top {
        top: 1rem;
    }

    &-bottom {
        bottom: 1rem;
    }

    .arrow {
        height: 3rem;
        background-size: contain;
    }
    .arrow-down-light {
        background: url(../img/icons/arrow-down--light.svg) 50% 50% no-repeat;
    }

    .arrow-up-light {
        background: url(../img/icons/arrow-down--light.svg) 50% 50% no-repeat;
        transform: rotate(180deg);
    }

    .arrow-down-dark {
        background: url(../img/icons/arrow-down--dark.svg) 50% 50% no-repeat;
    }

    .arrow-up-dark {
        background: url(../img/icons/arrow-down--dark.svg) 50% 50% no-repeat;
        transform: rotate(180deg);

    }

    a {
        color: transparent;
    }
}


.specifications-items {
    ul {
        list-style: none;
    }

    li {
        display: flex;
        flex-direction: column;
        padding-left: 2rem;
        position: relative;
    }

    h4 {
        display: inline-flex;
        align-items: center;
        font-weight: 100;
        margin-bottom: 0;

        &::before {
            position: absolute;
            left: 0;
            content: "\2022";
            font-size: 4rem;
            color: $yellow;
            width: 2rem;
        }
    }
}

.practical {
    .icon {
        padding: 1rem;
        border: 1px solid $white;
        border-radius: 50%;
        height: 2rem;
        width: 2rem;
        display: inline-block;
        background: center center no-repeat;
        background-size: 50%;

        // @include media-breakpoint-down(md) {
        //     padding: .5rem;
        //     height: 1rem;
        //     width: 1rem;
        //     background-size: 1rem;
        // }
    }
    .icon-truck {
        background-image: url(../img/icons/truck.svg);
    }
    .icon-forklift {
        background-image: url(../img/icons/forklift.svg);
    }
    .icon-check {
        background-image: url(../img/icons/check-circle.svg);
    }
    .icon-tools {
        background-image: url(../img/icons/tools.svg);
    }
    .icon-monitoring {
        background-image: url(../img/icons/monitoring.svg);
    }
    .icon-dimensions {
        background-image: url(../img/icons/dimensions.svg);
    }
}

.downArrow-wrapper {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    height: 55px;
    width: auto;
    z-index: 5;

    a {
        height: 100%;
        display: flex;
        align-self: stretch;
    }
}


.downArrow{
    height: 100%;
    display: flex;
    align-items: end;
    animation: bounce 3s infinite;

    .icon-arrow-down {
        background-image: url(../img/icons/arrow-down--light.svg);
        background-position: bottom;
    }

    @include media-breakpoint-down(md) {
        display: none;
    }
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-20px);
    }
    60% {
      transform: translateY(-10px);
    }
  }


  @keyframes wiggle {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(90deg);
    }
    100% {
        transform: rotate(0deg);
    }
  }
