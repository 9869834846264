.project-choose {
	text-align: center;
	margin-bottom: 2rem;
}

.project-container {
	margin: 4rem 0;

	@include media-breakpoint-down(md) {
		padding: 1rem 0;
	}
}

.project-image {
	position: relative;
	min-height: 25vh;
	.bg-img {
		background-size: contain;
		@include media-breakpoint-up(sm) {
			margin: 3rem 0;
		}
	}
}

.project-caption {
	font-size: .75rem;
}

.project-info {

	@include media-breakpoint-up(sm) {
		padding: 1rem 0;
	}

	.row {
		padding: .5rem 0;
		align-items: center;
		.btn {
			margin-bottom: 0;
		}
		// @include media-breakpoint-up(sm) {
		// 	padding-top: 0;
		// }
	}

	table.table {
		.form-control {
			display: flex;
			margin-left: 100px;
		}
	}

	.form-control {
		width: auto;
		text-align: center;
		font-size: $font-size-sm;
		font-weight: $font-weight-bold;
		width: 5rem;
		height: unset;
		padding: .25rem;


		&.input-number {
			margin: 0 10%;
			border: 2px solid $yellow;
			border-radius: 10px;
		}
	}



	label {
		@extend .col-sm-6;
		line-height: 1.5rem;
		font-size: 1rem;
		color: $gray-800;
		margin-bottom: 0;
		@include media-breakpoint-up(sm) {
			text-align: right;
			// padding-top: 0.75rem;
			// &.has-text {
			// 	padding-top: 0;
			// }
		}
		.form-text {
			color: $gray-500;
			position: relative;
			margin: -0.25rem 0 0;
			font-size: .8rem;
		}
	}

	.form-group {
		margin-bottom: 0;
		// padding: 0 2rem;
		display: flex;
		align-items: center;
		@extend .col-sm-6;
		@include media-breakpoint-down(md) {
			justify-content: center;
		}
	}

	label,
	.form-group {
		@extend .col-lg-6;
	}
	button,
	input {
		display: inline-block;
		vertical-align: top;
	}
}

.gf {
	font-weight: $font-weight-bold;
	display: block;
	text-align: center;
	color: $blue;
	@include media-breakpoint-up(lg) {
		margin: 0 0 -1rem;
	}
}

.btn-number {
	background: $gray-900;
	border-radius: 50%;
	border-color: $gray-300;
	color: $white;
	font-size: 2rem;
	line-height: 1rem;
	width: 2rem;
	height: 2rem;
	padding-left: 0;
	padding-right: 0;
	text-align: center;
	transition: all .5s ease;

	&:not(:last-child){
		margin-right: 0;
	}

	&:hover {
		background: $yellow;
		border-color: $blue;
		color: $blue;
		transition: all .5s ease;
	}
	+ small {
		display: block;
		margin-top: 0.5rem;
		@include media-breakpoint-up(xl) {
			margin-top: 0.75rem;
			float: right;
		}
	}
}
.btn-minus {
	span {
		display: inline-block;
		position: relative;
		top: -0.125rem;
	}
}

.radio-choose {
	@include media-breakpoint-up(sm) {
		margin: 0 0.5rem;
	}
	.btn {
		background: $white;
		border-color: $gray-300;
		font-weight: $font-weight-normal;
		margin: 1px;
		padding-left: 1.5rem;
		padding-right: 1.5rem;
		&:hover {
			border-color: $dark;
		}
	}
	input {
		position: absolute;
		opacity: 0;
		&:checked + .btn {
			border-color: $dark;
			border-width: 2px;
			font-weight: $font-weight-bold;
			box-shadow: 0 0 0 2px $yellow;
			position: relative;
			margin: 0;
		}
	}
}

// .form-checkbox {
// 	label {
// 		cursor: pointer;
// 		display: block;
// 		margin: 0;
// 		&:hover input[type="checkbox"]:not(.disabled) + span {
// 			&:before {
// 				border-color: $blue;
// 			}
// 		}
// 	}
// 	span {
// 		display: inline-block;
// 		line-height: 1.5rem;
// 		position: relative;
// 		padding-left: 2rem;
// 		vertical-align: top;

// 		&:before,
// 		&:after {
// 			content: "";
// 			display: inline-block;
// 			position: absolute;
// 			width: 1.25rem;
// 			height: 1.25rem;
// 			transition: all 0.3s ease-in-out;
// 		}
// 		&:before {
// 			background: $white;
// 			border: 1px solid $gray-300;
// 			border-radius: $border-radius;
// 			left: 0;
// 			top: 2px;
// 		}
// 		&:after {
// 			background: url(../img/check.svg);
// 			left: 2px;
// 			top: -1px;
// 			opacity: 0;
// 			z-index: 2;
// 		}
// 	}

	// input[type="checkbox"] {
	// 	opacity: 0;
	// 	position: absolute;
	// 	z-index: 1;

	// 	&:focus + span::before {
	// 	}

	// 	&:checked + span:after {
	// 		opacity: 1;
	// 	}
	// 	&.disabled + span:before {
	// 		opacity: 0.3;
	// 	}
	// 	&.disabled:checked + span:after {
	// 		opacity: 0.3;
	// 	}
	// 	&.disabled + span {
	// 		&:after {
	// 			cursor: not-allowed;
	// 		}
	// 	}
	// }
// }

// .form-radio {
// 	padding-left: 2rem;
// 	margin: 0.5rem 0 0.25rem;
// 	label {
// 		cursor: pointer;
// 		display: block;
// 		margin: 0;
// 		font-weight: $font-weight-normal;
// 		font-size: $font-size-sm;
// 		&:hover input[type="radio"]:not(.disabled) + span {
// 			&:before {
// 				border-color: $blue;
// 			}
// 		}
// 	}
// 	span {
// 		display: inline-block;
// 		line-height: 1rem;
// 		position: relative;
// 		padding-left: 1.5rem;
// 		vertical-align: top;

// 		&:before, &:after {
// 			content: "";
// 			display: inline-block;
// 			position: absolute;
// 			width: 1rem;
// 			height: 1rem;
// 			transition: all 0.3s ease-in-out;
// 			border-radius: 50%;
// 		}
// 		&:before {
// 			background: $white;
// 			border: 1px solid $gray-300;
// 			left: 0;
// 			top: 0;
// 		}
// 		&:after {
// 			background: $primary;
// 			width: 0.5rem;
// 			height: 0.5rem;
// 			left: 0.25rem;
// 			top: 0.25rem;
// 			opacity: 0;
// 			z-index: 2;
// 		}
// 	}

	// input[type="radio"] {
	// 	opacity: 0;
	// 	position: absolute;
	// 	z-index: 1;

	// 	&:focus + span:before {
	// 	}

	// 	&:checked + span:after {
	// 		opacity: 1;
	// 	}
	// 	&.disabled + span:before {
	// 		opacity: 0.3;
	// 	}
	// 	&.disabled:checked + span:after {
	// 		opacity: 0.3;
	// 	}
	// 	&.disabled + span {
	// 		&:after {
	// 			cursor: not-allowed;
	// 		}
	// 	}
	// }
// }

.chart-container {
	background-color: $gray-200;
	border-radius: $border-radius;
  // border-radius: $border-radius;
  // width: calc(100% - 2rem);
}

.chart-wrap {
	// border: 1px solid $gray-200;
	background: $gray-100;
	padding: 2rem;
	border-radius: $border-radius;
	@include media-breakpoint-up(lg) {
		margin: 0 2rem;
	}
}

.chart {
	border-radius: 100%;
	width: 20rem;
	height: 24rem;
	left: 50%;
	margin-left: -10rem;
	position: relative;

	canvas {
		position: relative;
		z-index: 10;
	}

	.result {
		position: absolute;
		height: 15rem;
		width: 15rem;
		text-align: center;
		top: 2.5rem;
		left: 2.5rem;
		border-radius: 100%;
		p {
			@extend .display-3;
			line-height: 1;
			margin: 4rem 0 0;
			span {
				display: inline-block;
				// float: left;
				// width: 7.5rem;
				vertical-align: top;
			}
			.euro {
				// text-align: right;
				// margin-left: -1.5rem;
			}
			.price {
				// text-align: left;
			}
		}
	}
	small {
		color: $gray-600;
		font-weight: $font-weight-bold;
		display: block;
		text-align: center;
	}
	canvas + small {
		position: absolute;
		width: 100%;
		top: 20rem;
	}
}
.legend {
	width: 15rem;
	@include list-unstyled();
	margin: 0;
	li {
		font-size: $font-size-base;
		font-weight: $font-weight-normal;
	}
	.icon {
		font-size: $h3-font-size;
	}
}
.lg-elec .icon {
	color: $yellow;
}
.lg-water .icon {
	color: $teal;
}
.lg-heat .icon {
	color: $orange;
}

.co2-table {
	margin: 0;
	th,
	td {
		padding-top: 0;
		border: 0;
	}
	th strong {
		color: $green;
	}
}

table + h5 {
	margin-top: 2rem;
}

#extra-lines {
	padding-top: 3rem;
	#options-box {
		width: 100% !important;
		+ .btn {
			width: auto;
			margin-left: 0;
			margin-top: $spacer;
		}
		@media (min-width: 1034px) {
			// width: 64% !important;
			+ .btn {
				width: 33%;
				margin-left: 7px;
				margin-top: 0;
			}
		}
	}
}

.extra-line {
	td {
		padding-left: 0;
	}
	.td {
		&-price {
			width: 8.75rem;
		}
		&-remove {
			padding-right: 0;
			width: 2.375rem;
		}
	}
}

.remove-extra-line {
	font-size: $h2-font-size;
	position: relative;
	height: 2.375rem;
	width: 2.375rem;

	span {
		position: absolute;
		display: block;
		top: -0.125rem;
		left: 1.25rem;
		height: 1rem;
		transform: rotate(45deg);
	}
}

.product-detail {
	height: 75vh;

	&--title {
		&::after {
			color: transparent;
			@extend .title-underline;
			content: "_____";
		}
	}

	&--image {
		.bg-img {
			// width: 75%;
			background-repeat: no-repeat;
			background-size: contain;
			background-position: right 10px top;
			@include media-breakpoint-up(sm) {
				margin: 3rem 0;
			}
		}
	}
}
