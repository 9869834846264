.card {
    &-img {
        background: $card-cap-bg;
        @include ratio(1 1);
        -webkit-backface-visibility: hidden;
       &-left {
            @include media-breakpoint-up(lg) {
                position: absolute;
                top: 0;
                bottom: 0;
                width: 40%;
                border-radius: 0;
                border-bottom-left-radius: .25rem;
                border-top-left-radius: .25rem;
                padding-right: 0;
            }
        }
        &-top {
            @include ratio(3 2);
        }
        &-4x3 {
            @include ratio(4 3);
        }
        &-16x9 {
            @include ratio(16 9);
        }
    }
}

li.card {
    border: 0;
    color: inherit;
    // cursor: pointer;
    text-decoration: none;
    @include transition($transition-base);
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    width: 100%;
    &:hover {
        // @include hoverboard();
    }
}
.teaser.card {
    cursor: default;
}