.services {
  .page-main {
      // margin: 0;
      padding-top: 0;
  }

  ul.info {

      @extend .list-unstyled;

      li {
          .icon {
            width: 6rem;
            height: 8rem;
            &.chrono {
              background: url(../img/icons/chrono.svg) 50% 50% no-repeat;
            }
            &.percent {
                background: url(../img/icons/percent.svg) 50% 50% no-repeat;
            }
            &.euro {
                background: url(../img/icons/money-saving.svg) 50% 50% no-repeat;
            }
            &.grow {
                background: url(../img/icons/grow.svg) 50% 50% no-repeat;
            }
            &.ripples {
                background: url(../img/icons/ripples.svg) 50% 50% no-repeat;
            }
            &.smile {
                background: url(../img/icons/smile.svg) 50% 50% no-repeat;
            }
          }



      }
  }
}
