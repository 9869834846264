.datepicker {
  border-radius: $border-radius;
  &-inline {
    width: 220px;
  }
  direction: ltr;
  &-rtl {
    direction: rtl;
    &.dropdown-menu { left: auto; }
    table tr td span {
      float: right;
    }
  }
  &-dropdown {
    top: 0;
    left: 0;
    padding: 4px;
    &:before {
      content: '';
      display: inline-block;
      border-left:   7px solid transparent;
      border-right:  7px solid transparent;
      border-bottom: 7px solid $dropdown-border-color;
      border-top:    0;
      border-bottom-color: rgba(0,0,0,.2);
      position: absolute;
    }
    &:after {
      content: '';
      display: inline-block;
      border-left:   6px solid transparent;
      border-right:  6px solid transparent;
      border-bottom: 6px solid $dropdown-bg;
      border-top:    0;
      position: absolute;
    }
    &.datepicker-orient-left:before   { left: 6px; }
    &.datepicker-orient-left:after    { left: 7px; }
    &.datepicker-orient-right:before  { right: 6px; }
    &.datepicker-orient-right:after   { right: 7px; }
    &.datepicker-orient-bottom:before { top: -7px; }
    &.datepicker-orient-bottom:after  { top: -6px; }
    &.datepicker-orient-top:before {
      bottom: -7px;
      border-bottom: 0;
      border-top:    7px solid $dropdown-border-color;
    }
    &.datepicker-orient-top:after {
      bottom: -6px;
      border-bottom: 0;
      border-top:    6px solid $dropdown-bg;
    }
  }
  table {
    margin: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    tr {
      td, th {
        text-align: center;
        width: 30px;
        height: 30px;
        border-radius: 4px;
        border: none;
      }
    }
  }
  // Inline display inside a table presents some problems with
  // border and background colors.
  .table-striped & table tr {
    td, th {
      background-color: transparent;
    }
  }
  table tr td {
    &.old,
    &.new {
      color: $btn-link-disabled-color;
    }
    &.day:hover,
    &.focused {
      background: $light;
      cursor: pointer;
    }
    &.disabled,
    &.disabled:hover {
      background: none;
      color: $btn-link-disabled-color;
      cursor: default;
    }
    &.highlighted {
      $highlighted-bg: $gray-300;
      @include button-variant(#000, $highlighted-bg, darken($highlighted-bg, 20%));
      border-radius: 0;

      &.focused {
        background: darken($highlighted-bg, 10%);
      }

      &.disabled,
      &.disabled:active {
        background: $highlighted-bg;
        color: $btn-link-disabled-color;
      }
    }
    &.today {
      $today-bg: $light;
      @include button-variant($today-bg, $today-bg, $blue);
      color: $primary;
      &.focused {
        background: darken($today-bg, 10%);
      }

      &.disabled,
      &.disabled:active {
        background: $today-bg;
        color: $btn-link-disabled-color;
      }
    }
    &.range {
      $range-bg: $light;
      @include button-variant(#000, $range-bg, darken($range-bg, 20%));
      border-radius: 0;

      &.focused {
        background: darken($range-bg, 10%);
      }

      &.disabled,
      &.disabled:active {
        background: $range-bg;
        color: $btn-link-disabled-color;
      }
    }
    &.range.highlighted {
      $range-highlighted-bg: mix($gray-300, $light, 50%);
      @include button-variant(#000, $range-highlighted-bg, darken($range-highlighted-bg, 20%));

      &.focused {
        background: darken($range-highlighted-bg, 10%);
      }

      &.disabled,
      &.disabled:active {
        background: $range-highlighted-bg;
        color: $btn-link-disabled-color;
      }
    }
    &.range.today {
      $range-today-bg: mix(orange, $light, 50%);
      @include button-variant(#000, $range-today-bg, darken($range-today-bg, 20%));

      &.disabled,
      &.disabled:active {
        background: $range-today-bg;
        color: $btn-link-disabled-color;
      }
    }
    &.selected,
    &.selected.highlighted {
      @include button-variant($white, $gray-600, $gray-600);
      // text-shadow: 0 -1px 0 rgba(0,0,0,.25);
    }
    &.active,
    &.active.highlighted {
      @include button-variant($primary, $primary, $primary);
      background-color: $primary !important;
      border-color: $primary !important;
      // text-shadow: 0 -1px 0 rgba(0,0,0,.25);
    }
    span {
      display: block;
      width: 23%;
      height: 54px;
      line-height: 54px;
      float: left;
      margin: 1%;
      cursor: pointer;
      border-radius: 4px;
      &:hover,
      &.focused {
        background: $light;
      }
      &.disabled,
      &.disabled:hover {
        background: none;
        color: $btn-link-disabled-color;
        cursor: default;
      }
      &.active,
      &.active:hover,
      &.active.disabled,
      &.active.disabled:hover {
        @include button-variant($white, $primary, $primary);
        text-shadow: 0 -1px 0 rgba(0,0,0,.25);
      }
      &.old,
      &.new {
        color: $btn-link-disabled-color;
      }
    }
  }

  .datepicker-switch {
    width: 145px;
  }

  .datepicker-switch,
  .prev,
  .next,
  tfoot tr th {
    cursor: pointer;
    &:hover {
      background: $light;
    }
  }

  .prev, .next {
    &.disabled {
      visibility: hidden;
    }
  }

  // Basic styling for calendar-week cells
  .cw {
    font-size: 10px;
    width: 12px;
    padding: 0 2px 0 5px;
    vertical-align: middle;
  }
}
.input-group.date .input-group-addon {
  cursor: pointer;
}
.input-daterange {
  width: 100%;
  input {
    text-align: center;
  }
  input:first-child {
    border-radius: 3px 0 0 3px;
  }
  input:last-child {
    border-radius: 0 3px 3px 0;
  }
  .input-group-addon {
    width: auto;
    min-width: 16px;
    padding: 4px 5px;
    line-height: $line-height-base;
    border-width: 1px 0;
    margin-left: -5px;
    margin-right: -5px;
  }
}