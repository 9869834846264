.modal-header {
  background: $gray-100;
  border: 0;
  color: $gray-700;
  padding-left:  2rem;
  .modal-title {
    position: relative;
    padding-left: 2.5rem;
  }
  svg {
    font-size: 1.25em;
    vertical-align: top;
    color: $gray-400;
    position: absolute;
    left: 0;
    top: .25rem;
  }
  .close {
    opacity: 1;
    font-weight: 500;
    line-height: 1.5em;
    position: relative;
    padding-right: 3rem;
    cursor: pointer;
    transition: color .3s ease;
    &:hover {
      color: $gray-900;
    }
    span {
      font-size: 2em;
      position: absolute;
      right: 1rem;
      top: 1rem;
      vertical-align: middle;
    }
  }
}