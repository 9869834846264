$r-spacer-x: $spacer * 1;
$r-spacer-y: $spacer * 2;

$r-maxwidth: 1920px;
$r-maxwidth-sm: $r-maxwidth * .75;

* {
	margin: 0;
	padding: 0;
  }

.page-header {
	.r-container {
		padding: 0 $r-spacer-x;
		@include media-breakpoint-up(md) {
			padding: 0 ($r-spacer-x * 2);
		}
	}
}

.r-section {
	padding-bottom: $r-spacer-y;
	position: relative;
	margin: auto;

	&.r-section-lg {
		padding-bottom: $r-spacer-y * 3;
	}
	@include media-breakpoint-up(md) {
		padding-bottom: ($r-spacer-y * 2);
		&.r-section-sm {
			padding-bottom: $r-spacer-y;
		}
		&.r-section-lg {
			padding-bottom: $r-spacer-y * 4;
		}
	}
	@include media-breakpoint-up(xl) {
		padding-bottom: ($r-spacer-y * 3);
		&.r-section-sm {
			padding-bottom: ($r-spacer-y * 1.5);
		}
		&.r-section-lg {
			padding-bottom: $r-spacer-y * 5;
		}
	}
}

.r-hasbg, .r-hasbg + section {
	padding-top: $r-spacer-y;
	&.r-section-lg {
		padding-top: $r-spacer-y * 3;
	}
	@include media-breakpoint-up(md) {
		padding-top: ($r-spacer-y * 2);
		&.r-section-sm {
			padding-top: $r-spacer-y;
		}
		&.r-section-lg {
			padding-top: $r-spacer-y * 4;
		}
	}
	@include media-breakpoint-up(xl) {
		padding-top: ($r-spacer-y * 3);
		&.r-section-sm {
			padding-top: $r-spacer-y * 1.5;
		}
		&.r-section-lg {
			padding-top: $r-spacer-y * 5;
		}
	}
}

// .container {
// 	max-width: $r-maxwidth;
// }

.r-offset {
	.r-container {
		max-width: $r-maxwidth-sm;
	}
}

.r-boxed {
  margin: auto;

	@include media-breakpoint-up(sm) {
		width: calc(100% - #{$r-spacer-x * 2});
		max-width: $r-maxwidth;
		.r-container {
			padding: 0 $r-spacer-x;
			// padding: 0;
		}
	}
	@include media-breakpoint-up(xl) {
		.r-container {
			padding: 0 ($r-spacer-x * 2);
		}
	}

	&.r-offset {
		max-width: $r-maxwidth-sm;
	}
}

.r-container {
	max-width: $r-maxwidth;
	@extend .container-fluid;
	padding: 0 $r-spacer-x;
	@include media-breakpoint-up(md) {
		padding: 0 ($r-spacer-x * 2);
	}
}

.r-row {
	@extend .row;
}
.r-row-center {
	@extend .justify-content-center;
}
.r-row-vcenter {
	@extend .align-items-center;
}
.r-row-start {
	@extend .justify-content-start;
}
.r-row-end {
	@extend .justify-content-end;
}

.r-col {
	padding-bottom: $r-spacer-y / 2;
	padding-top: $r-spacer-y / 2;
}


.r-col-single {
	@extend .col-sm-12;
}
.r-col-half {
	@extend .col-md-6;
}
.r-col-one-third {
	@extend .col-md-4;
}
.r-col-two-third {
	@extend .col-md-8;
}
.r-col-one-fourth {
	@extend .col-sm-6;
	@extend .col-md-3;
}


.r-col-half {
	&.r-col-img, &.r-col-text {
		@extend .col-lg-5;
	}
	&.r-col-text {
		@include media-breakpoint-up(lg) {
			padding-left: $spacer * 2;
		}
	}
}

.r-hasbg {
	.r-container {
		position: relative;
		z-index: 2;
	}
}

.lazybg {
	background-repeat: no-repeat;
	background-size: cover;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 1;
}

.r-heading {
	.r-col-single {
		@extend .col-sm-8;
	}
}
