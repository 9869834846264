html {
    scroll-behavior: smooth;
}

body {
    padding-top: $nav-link-height;
    @include media-breakpoint-up(sm) {
        padding-top: $nav-link-height + .5rem;
    }
}

// .page {
//     display: contents;
// }

a {
	transition: color 0.15s ease-in-out;
}

.hidden {
	display: none;
}

:focus,
button:focus {
	outline: 0;
}

main, .page {
    // background: $white;
    border-radius: $border-radius;
    // &:last-of-type section {
    //   border-radius: 0 0 $border-radius $border-radius;
    // }
}

.page {
    &-main {
        background: $white;
        @include media-breakpoint-up(sm) {
            padding-top: $r-spacer-x;
            margin-top: $r-spacer-x;
            margin-bottom: $r-spacer-x;
        }
    }
    &-intro {
        padding: 1rem 0;
        text-align: center;
        @include media-breakpoint-up(md) {
		    padding: 2rem 0;
	    }
    }
}

h1.logo {
	font-size: 2rem;
	img {
		display: inline-block;
		height: 1.5em;
		width: 1.5em;
		vertical-align: middle;
	}
}

.hero {
	background-color: $gray-100;
	background-size: cover;
	background-position: bottom center;
	padding: 6rem 0;
	@include media-breakpoint-up(md) {
		padding: 12rem 0;
	}
}

.text {
	ul {
		padding-left: 20px;
	}
}
.r-faded {
	.lazybg.lazyloaded {
		opacity: 0.5;
		@include media-breakpoint-up(md) {
			opacity: 1;
		}
	}
}
.r-hasbg {
	background-color: $gray-100;
}
.r-boxed.r-hasbg + .r-hasbg {
	margin-top: $grid-gutter-width;
}
.r-boxed, .r-boxed .r-section-bg {
	@include media-breakpoint-up(sm) {
		border-radius: $border-radius;
	}
}

.columns {
	@include media-breakpoint-up(md) {
		column-count: 2;
		column-gap: $grid-gutter-width;
	}
}

th {
	font-weight: 500;
	font-size: $font-size-sm;
	@include media-breakpoint-up(md) {
		font-size: $font-size-base;
	}
	strong {
		text-transform: lowercase;
	}
}

td {
	a {
		display: block;
		text-decoration: none;
		color: $gray-400;
		line-height: 1.5rem;
		&:hover,
		&:focus {
			color: theme-color("primary");
		}
	}
}

.table-advantages {
	th {
		font-size: $font-size-sm;
		@include media-breakpoint-up(md) {
			font-size: $h4-font-size;
		}
	}
}

.lt-technical {
	background: url(../img/lt-technical.png);
	background-size: cover;
	@include list-unstyled();
	height: 0;
	padding-top: 100%;
	position: relative;
	margin: 0;
	li {
		overflow: hidden;
		position: absolute;
		left: 50%;
		top: 50%;
		height: 1.5rem;
		text-indent: 999em;
		width: 1.5rem;
	}
}

.lt-technical li, .haspoi tr {
	counter-increment: step-counter;
}

.lt-technical li, .haspoi tr {
	&:before {
		content: counter(step-counter);
		background: $yellow;
		color: $white;
		display: block;
		position: absolute;
		text-align: center;
		height: 1rem;
		width: 1rem;
		font-weight: 500;
		border-radius: 50%;
		text-indent: 0;
		font-size: 10px;
		z-index: 10;
		transition: all 300ms ease;
		@include media-breakpoint-up(md) {
			font-size: $font-size-sm;
			padding-top: 0.125rem;
			height: 1.5rem;
			width: 1.5rem;
		}
	}
	&.hover {
		&:before {
			background: $blue;
		}
	}
}

.haspoi tr:before {
	right: -1rem;
	margin: 1rem 0.5rem 0 0;
	@include media-breakpoint-up(md) {
		right: -2rem;
	}
}

// .r-section ul {
// 	@include list-unstyled();
// 	li {
// 		margin: .5em 0;
// 		position: relative;
// 		padding-left: 1em;
// 		a {
// 			color: $gray-800;
// 			text-decoration: $link-decoration;
// 			&:hover, &:focus {
// 				color: $primary;
// 			}
// 		}
// 		&:before {
// 		    content: '\2022';
// 		    color: $green;
// 		    left: 0;
// 		    position: absolute;
// 		}
// 	}
// }

.fixed-cta {
	right: 0.5rem;
	position: fixed;
	bottom: 0;
	z-index: 1000;
	a {
    @extend .btn;
    @extend .btn-yellow;
    border-radius: $border-radius $border-radius 0 0;
    margin-bottom: 0;
    &:not(:last-child) {
      margin-right: 0.25rem;
    }
	}

	.cta-home {
		background-image: url(../img/icons/house-fill-dark.svg);
		background-repeat: no-repeat;
		background-size: 2rem;
		background-position: center;
		transition: .5s all ease;
		&:hover {
			background-image: url(../img/icons/house-fill.svg);
			transition: .5s all ease;
		}
	}
}

.contact {
	.cta-contact {
		display: none;
	}
}

.cost-color {
	background: $white;
	border-radius: 5px;
	display: block;
	padding: 25px 15px;
	list-style-type: none;
	li {
		position: relative;
		padding-left: 35px;
		margin-bottom: 10px;
		&:before {
			content: "";
			display: inline-block;
			position: absolute;
			left: 0;
			top: 4px;
			width: 20px;
			height: 20px;
			border-radius: 50%;
			background: $teal;
		}
		&:nth-child(2) {
			&:before {
				background: $orange;
			}
		}
		&:nth-child(3) {
			margin-bottom: 0;
			&:before {
				background: $yellow;
			}
		}
	}
}
.energy-image {
	margin-bottom: 30px;
	width: 40%;
	@include media-breakpoint-down(sm) {
		width: 50%;
	}
}
.energy-color {
	width: 25%;
	@include media-breakpoint-down(sm) {
		width: 50%;
	}
}
.iframe-section {
	border: 0;
	margin-bottom: $grid-gutter-width;
}
.form {
	max-width: ($r-maxwidth / 1.4);
}

.lito-view {
	position: relative;
	overflow: hidden;
	padding-bottom: 66.66%;
	margin-top: -$spacer * 2;
	width: 100%;
}

#pano {
	position: absolute;
	width: 100%;
	height: 100%;
}

.flanders-logo {
	display: block;
	overflow: hidden;
	width: 60%;
	text-align: left;
	margin-top: $spacer * 2;
    @include media-breakpoint-up(lg) {
        width: 33.33%;
    }
	i {
		display: block;
		float: left;
		background: url(../img/logo-vlaanderen.png) no-repeat;
		background-size: contain;
		width: $spacer * 3;
		height: $spacer * 3;
	}
	p {
		float: left;
		width: 80%;
		margin-bottom: 0;
		font-size: $font-size-sm;
	}
}

.home {
	.page-main {
		display: flex;
		min-height: 80vh;

		h1 {
			font-size: $display1-size;
			@include media-breakpoint-down(md) {
				font-size: 3rem;
			}
		}
	}
    & > .page > .page-footer {
        display: none;
    }

	.fullscreen-bg {
		// I'd rather have the element moved, but I've set the position to fixed since it's a quicker fix and these feedback rounds keep coming
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
	}

	.fullscreen-bg__video {
		object-fit: cover;
		width: 100vw;
		height: 100vh;
		position: absolute;
		top: 0;
		left: 0;
	}

    .flanders {
        display: none;
    }
}

.homepage {
    margin: auto;
    @include media-breakpoint-down(sm) {
        margin-top: 2.5rem;
        width: 100%;
    }
}

.typewriter {
	min-height: $display1-size + 1rem;
	.wrap {
		position: relative;
		min-height: 100%;
		display: block;
		&:after {
			content: '';
			position: absolute;
			background-color: $yellow;
			width: .25rem;
			height: 100%;
			top: 0;
			// right: -.25rem;
			animation: cursor 1s step-start infinite;
		}
	}
}

@keyframes cursor {
	50% {
		opacity: 0;
	}
}

.cursor {
	&-pointer * { cursor: pointer; }
}

.downloads {
	ul.downloads {
		display: grid;
    	grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
    	gap: $card-columns-gap;
		@extend .list-unstyled;

		@include media-breakpoint-down(sm) {
			grid-template-columns: 1fr 1fr;
		}

		li {
			text-align: center;
		}

        .glowing-border {
            border: initial;
            border: solid 2px $dark;
            font-weight: $font-weight-bold;
            box-shadow: 0 0 0 2px $yellow;
            position: relative;
            margin: 0;
        }

	}
}

.title-underline {
	margin-bottom: 2rem;
	text-decoration: underline;
	text-underline-offset: 0.5rem;
	line-height: 1.5em;
}

.bg-contain {
	background-size: contain;
}

.overlay-white {
	position: absolute;
	top:0;
	left:0;
	bottom: 0;
	right: 0;
	background-color: rgba($white, .75);
    min-height: 100%;
}

.bg-gradient {
    background: rgb(196,196,196);
	background: linear-gradient(0deg, rgba(196,196,196,1) 0%, rgba(255,255,255,1) 40%, rgba(255,255,255,1) 100%);
}

.transition {
	transition: all .3s ease-in-out;
}


  // development class
.pink {
	border: 10px solid pink;
}

// scroll down arrow on the homepage

.downArrow-wrapper {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    height: 75px;
    width: auto;
    z-index: 5;

    a {
        height: 100%;
        display: flex;
        align-self: stretch;
    }
}

.icon-white {
	fill: $white;
}

.embed-responsive iframe {
    border-radius: $border-radius;
}

.embed-responsive {
    @include border-radius($border-radius);
}

// A simple class to add consistency to the last element of the page
.border-bottom-radius {
  border-radius: 0 0 $border-radius $border-radius;
}

// ...and another rule to style the elements not reachable by the previous rule
main.page-main {
    .r-section:last-child, .slider-slide:last-child {
        border-radius: 0 0 $border-radius $border-radius;
    }
    .slider-slide:first-of-type {
        border-radius: $border-radius $border-radius 0 0;
    }
}

// Cookie message
.cc-window {
    font-family: $font-family-base;
    z-index: 1000;
    border-radius: $border-radius;
    box-shadow: 0 1rem 2.5rem -1.25rem rgba($black,.25);
    .cc-btn {
        font-weight: $font-weight-normal;
        @extend .btn;
        @extend .btn-yellow;
        margin-bottom: 0;
        &:hover, &:focus, &:active {
          @extend .btn-yellow;
            background: #deaf00 !important;
            border-color: #deaf00 !important;
        }
    }
}
