.page-footer {
	border-top: 1px solid $gray-300;
	text-align: center;
	padding-top: 2rem;
	color: $gray-600;
	font-size: $font-size-sm;
	a {
		color: $gray-600;
		text-decoration: none;
		&:hover {
			color: $gray-900;
		}
	}
}

.page-footer {
	#footer {
		display: inline-block;
		li {
			display: inline-block;
			&:after {
				content: '-';
				padding-left: 4px;
			}
			&:last-child {
				&:after {
					display: none;
				}
			}
		}
	}
}
.footer {
	&-nav {
		@include list-unstyled();
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		li {
			+ li {
				padding-left: .75rem;
				&:before {
					background: $gray-300;
					display: inline-block;
					content: "";
					border-radius: 50%;
					height: .25rem;
					width: .25rem;
					margin-right: .75rem;
					vertical-align: middle;
				}
			}
		}
	}
}

// STICKY FOOTER
html, body, .page {
	height: 100%;
}
.page {
	display: flex;
	flex-direction: column;
}
main {
	flex-shrink: 0;
}
footer {
	margin-top: auto;
}


