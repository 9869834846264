img {
	@extend .img-fluid;
	width: 100%;
  border-radius: $border-radius;
}
picture {
    display: block;
}

figure {
    // background: $light;
    margin: 0;
    // @include ratio(3 2);
    img {
        object-fit: cover;
    }
}

.lazyload, .lazyloading {
	opacity: 0;
}
.lazyloaded {
	transition: opacity 400ms;
	opacity: 1;
}

@include media-breakpoint-up(md) {
    .cover {
        figure {
            border-radius: 0 10rem 10rem 0;
            width: calc(50vw - .75rem);
            height: 20rem;
            position: relative;
            max-width: none;
            float: right;
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .col + .col {
        .cover {
            float: none;
            figure {
                float: none;
                border-radius: 10rem 0 0 10rem;
            }
        }
    }
}
@include media-breakpoint-up(lg) {
    .cover {
        figure {
            height: 30rem;
        }
    }
    .col + .col {
        .cover {
            figure {

            }
        }
    }
}
@include media-breakpoint-up(xl) {
    .cover {
        figure {
            width: calc(100% + 5.625rem);
        }
    }
}
