

// Ratio
@mixin ratio($ratio: 1 1, $selector: "&:before") {
    $selector: unquote($selector);
    position: relative;
    overflow: hidden;
    #{$selector} {
        content: '';
        display: block;
        padding-bottom: percentage(nth($ratio, 2) / nth($ratio, 1));
        height: 0;
    }
    img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}

@mixin hoverboard() {
    transform: scale(1.075);
}

@mixin font-small() {
    font-size: $font-size-xs;
    text-transform: uppercase;
}