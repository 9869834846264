.files-list {
    .card {
        &-header {
            position: relative;
            display: flex;
            align-items: baseline;
            text-decoration: none;
            background: none;
            padding-top: 0;
            @include transition($transition-base);

            @include media-breakpoint-down(md) {
                padding: .25rem 0;
            }

            h3 {
                color: $gray-900;
                font-size: $font-size-base;
                margin-bottom: 0;
                @include transition($transition-base);
                @include media-breakpoint-up(lg) {
                    font-size: 1.125rem;
                }
            }

            &:hover {
                h3 {
                    color: $blue;
                    @include transition($transition-base);
                }
                svg {
                    fill: $blue;
                    @include transition($transition-base);
                }
            }
        }
        &-body {
            margin-left: 2rem;

            @include media-breakpoint-down(md) {
              margin-left: .3rem;
            }

            @include media-breakpoint-down(sm) {
                margin-left: .5rem;
            }

            .file {
                a {
                    // display: flex;
                    // justify-content: space-between;
                    color: $gray-800;
                    text-decoration: none;
                    @include transition($transition-base);
                    & svg {
                        fill: $gray-800;
                        @include transition($transition-base);
                    }
                    &:hover {
                        color: $blue;
                        @include transition($transition-base);
                        svg {
                            fill: $blue;
                            @include transition($transition-base);
                        }
                    }
                }

                &:not(:last-child) {
                    border-bottom: solid 1px rgba($gray-600, .25);
                }
            }
            .icon {
                width: 1em;
                height: 1.25em;
            }
        }
    }
}

.accordion {
    .card:only-child {
        border-bottom: 1px solid $card-border-color;
        border-radius: $border-radius;
    }
    &-indicator {
        width: 1.5rem;
        height: 1.5rem;
        line-height: 1.5rem;
        margin-right: .5rem;
        transform: rotate(0deg);
        transition: transform 0.25s ease-in-out;
        text-align: center;
        .icon {
            font-size: 1rem;
            fill: $body-color;
        }
    }
    .collapsed .accordion-indicator {
        transform: rotate(-90deg);
        @include transition($transition-base);
    }
}

.list-faq {
    @include media-breakpoint-up(lg) {
        @include row-cols(3);
    }
    @include media-breakpoint-up(xl) {
        @include row-cols(3);
    }
}
