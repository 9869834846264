.icon {
  fill: currentColor;
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: -.2em;
  overflow: hidden;
}
.icon-arrow-right {
	margin-left: .2em;
}