body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	@include media-breakpoint-up(lg) {
		font-size: 1.125rem;
	}
}

b, strong {
	font-weight: $font-weight-bold;
}

h1, h2, h3 {
	letter-spacing: -.02em;
}

h1, h2 {
	font-size: $h3-font-size;
	@include media-breakpoint-up(md) {
		font-size: $h2-font-size;
	}
	@include media-breakpoint-up(lg) {
		font-size: $h1-font-size;
	}
}

h3, .intro {
	font-size: $h5-font-size;
	@include media-breakpoint-up(md) {
		font-size: $h4-font-size;
	}
	@include media-breakpoint-up(lg) {
		font-size: $h3-font-size;
	}
}
h3 {
	.icon {
		font-size: 1.2em;
	}
}
picture+.text {
	h3 {
		margin: 2rem 0 1rem;
	}
}
