.r-thanks {
  background: $green;
  color: $white;
  h2 {
    color: $white;
  }
  a {
    color: rgba($black, .5);
    text-decoration: underline;
    &:hover {
      color: $white;
    }
  }
  p:not(.lead) {
    margin: 0;
  }
}

.form__row {
  @include make-row();
  @extend .form-group;
}
.form__label {
  @extend .col-sm-4;
  @include media-breakpoint-up(sm) {
    text-align: right;
  }
  label {
    @extend .col-form-label;
    &:after {
      content:':';
    }
    &.required {
      &:after {
        content:'*:';
      }
    }
  }
}
.form__field, .form__note, .buttons {
  @extend .col-sm-8;
}
.form__note, .buttons {
  @extend .offset-sm-4;
  padding: 0;
  @include media-breakpoint-up(sm) {
    padding: 0 .5rem;
  }
}
.form__field {
  input, textarea {
    @extend .form-control;
    font-size: inherit;

  }
  .parsley-error {
    @extend .form-control.is-invalid;
    &:focus {
      border-color: $form-feedback-invalid-color;
    }
  }
  .parsley-success:focus {
    @extend .form-control.is-valid;
    border-color: $form-feedback-valid-color;
  }
}

.form-control {
  &.parsley-error {
    @extend .form-control.is-invalid;
    &:focus {
      border-color: $form-feedback-invalid-color;
    }
  }
  &.parsley-success:focus {
    @extend .form-control.is-valid;
    border-color: $form-feedback-valid-color;
  }
}

.parsley-required {
  text-align: left !important;
}

.form__note {
  font-size: $font-size-sm;
  @extend .form-group;
}
.buttons {
  button {
    @extend .btn;
    @extend .btn-primary;
  }
}
.parsley-errors-list {
  @include list-unstyled();
  margin: 0;
  li {
    @extend .invalid-feedback;
    display: block;
  }
}

input[type="submit"].link {
  background: none;
	color: $blue;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

input[type="submit"].link:hover {
  text-decoration: underline;
}
