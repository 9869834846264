.btn {
	margin-bottom: $grid-gutter-width /2;
	@include media-breakpoint-down(sm) {
		width: 100%;
	}
	&:not(:last-child) {
		@include media-breakpoint-up(lg) {
			margin-right: $grid-gutter-width;
		}
	}
}

.btn-link {
	font-weight: 500;
	font-size: inherit;
	padding-left: 0;
	padding-right: 0;
	transition: color .15s ease-in-out;
	&:hover {
		color: $gray-900;
		text-decoration: none;
	}
}

.btn-down {
	width: 3.5rem;
	height: 3.5rem;
	line-height: 3.5rem;
	font-size: $font-size-lg;
	border-radius: 50%;
	padding: 0;

	svg {
		transition: fill .3s ease-in-out;
	}

	&:hover svg{
		fill: $yellow;
		transition: fill .3s ease-in-out;
	}
}

picture+.btn {
	margin-top: 1rem;
}

.btn-outline {
	border: 1px solid $white;
	font-weight: 100;
	color: $white;
	border-radius: 15px;
	padding: .5rem 2rem;
	opacity: 1;
	transition: .25s ease-in all;

	&:hover {
		color: $white;
		opacity: .75;
		transition: .25s ease-in all;
	}
}
