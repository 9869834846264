.page-header {
    @extend .navbar;
    @extend .navbar-expand-sm;
    @extend .navbar-light;
    @extend .bg-light;
    @extend .fixed-top;

    .navbar-stripe {
        height: 2px;
        background: $yellow;
        @include media-breakpoint-down(sm) {
          display: none;
        }
    }

    .navbar-nav {
        @include media-breakpoint-down(sm) {
            width: 100%;
        }

        @include media-breakpoint-up(sm) {
          width: auto;
        }

        .nav-link {
            padding-left: $nav-link-padding-x / 1.5;
            padding-right: $nav-link-padding-x / 1.5;
            @include media-breakpoint-up(md) {
                padding-left: $nav-link-padding-x;
                padding-right: $nav-link-padding-x;
            }
        }
    }
}

.navbar {
    @include media-breakpoint-down(sm) {
        .dropdown-item {
            color: $body-color;
            text-align: center;
            &:hover, &.active {
                color: $primary;
            }
        }
    }
}


.navbar-brand {
    font-weight: 500;
}

.navbar-brand, .navbar-nav {
    background: $gray-100;
    position: relative;
    z-index: 10;
}

.navbar-brand {
    h2 {
        @include text-hide;
        height: 40px;
        width: 60px;
        background: url(../img/logo.svg) no-repeat;
        background-size: contain;
        margin: 0;
    }
}

.nav-item {
    font-weight: 500;
    font-size: 1.25rem;
    @include media-breakpoint-up(sm) {
        position: relative;
        &:after {
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid transparent;
            position: absolute;
            bottom: 0px;
            left: 50%;
            margin-left: -10px;
        }
        &.active:after {
            border-bottom-color: $yellow;
        }
    }
    @include media-breakpoint-up(sm) {
        font-size: 1rem;
    }
}

@include media-breakpoint-down(xs) {
    .navbar-collapse {
        background: $gray-100;
        text-align: center;
        overflow: auto;
        padding-bottom: $spacer;
    }
    .nav-item {
        margin: 0.5rem 0 0;
    }
    .nav-link {
        background: $white;
        padding: 0.75rem 0;
    }
}

.navbar-toggler {
    height: 2.5rem;
    width: 2.5rem;
    cursor: pointer;
    // position: absolute;
    // right: 75px;
    // top: 8px;
    // @include media-breakpoint-up(sm) {
    //     right: 85px;
    // }
    &:focus {
        outline: none;
    }
    &-bars,
    &-bars:before,
    &-bars:after {
        background: $navbar-light-color;
        display: block;
        width: 1.5rem;
        height: 2px;
        margin: auto;
        position: relative;
        transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
        &:last-child {
            margin-bottom: 0;
        }
    }
    &-bars:before,
    &-bars:after {
        content: "";
        position: absolute;
        left: 0;
    }
    &-bars:before {
        top: -6px;
    }
    &-bars:after {
        bottom: -6px;
    }

    &-bars-close {
        background: transparent;
        &:before {
            transform: rotate(45deg) translate(4px, 4px);
        }
        &:after {
            transform: rotate(-45deg) translate(4px, -4px);
        }
    }
}



.language-switch {
    a {
        font-size: $font-size-sm;
        color: $gray-900;
        padding: 0.5rem;
        font-weight: 700;
        background: transparent;
        &:hover,
        &:focus {
            color: $blue;
        }
    }
    .active {
        color: $blue;
    }
    // @include media-breakpoint-down(sm) {
    //     position: absolute;
    //     right: 0;
    //     top: 2px;
    //     .nav-link {
    //         background: transparent;
    //     }
    // }

}

.dropdown-menu.dropdown-language-switch {
    min-width: unset;
    border: 0;
    // added some padding and margin to counter te dropdown arrow in the dropdown button
    margin-left: .255em;
    padding-right: .5em;
}

.dropdown-menu {
    background-color: #fffdfa;
    border-radius: 0 0 $border-radius $border-radius;
    box-shadow: 0 1rem 2.5rem -1.25rem rgba($black,.25);
    @include media-breakpoint-down(sm) {
        background-color: $white;
    }
    .dropdown-item {
            &:last-of-type {
                &:hover, &.active {
                    border-radius: 0 0 $border-radius $border-radius;
                }
            }
    }
}

// Make the dropdown menu active on hover, instead of on click
@include media-breakpoint-up(sm) {
    .nav-item.dropdown:hover > .dropdown-menu {
        display: block;
        margin-top: 0;
    }
    .nav-item.dropdown > .dropdown-toggle:active {
        pointer-events: none;
    }
}