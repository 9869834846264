input[type="radio"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Rremove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: var(--form-background);
    /* Not rremoved via appearance */
    margin: 0;
  
    font: inherit;
    color: currentColor;
    width: 1rem;
    height: 1rem;
    border: 0.15rem solid currentColor;
    border-radius: 50%;
    transform: translateY(-0.075rem);
  
    display: grid;
    place-content: center;
  }
  
  input[type="radio"]::before {
    content: "";
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1rem 1rem var(--form-control-color);
    /* Windows High Contrast Mode */
    background-color: CanvasText;
  }
  
  input[type="radio"]:checked::before {
    transform: scale(1);
  }

// square radiobuttons

input[type="radio"].square {
  width: 1.15rem;
  min-width: 1.15rem;
  height: 1.15rem;
  border-radius: 0.15rem;
  transform: translateY(-0.075rem);
}

input[type="radio"].square::before {
  content: "";
  width: 0.65rem;
  height: 0.65rem;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}