input[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Rremove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: var(--form-background);
    /* Not rremoved via appearance */
    margin: 0 1rem 0 0;
  
    font: inherit;
    color: currentColor;
    width: 1.15rem;
    min-width: 1.15rem;
    height: 1.15rem;
    border: 0.15rem solid currentColor;
    border-radius: 0.15rem;
    transform: translateY(-0.075rem);
  
    display: grid;
    place-content: center;
  }
  
  input[type="checkbox"]::before {
    content: "";
    width: 0.65rem;
    height: 0.65rem;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1rem 1rem var(--form-control-color);
    /* Windows High Contrast Mode */
    background-color: CanvasText;
  }
  
  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }

  .form-control--disabled {
    // color: var(--form-control-disabled);
    opacity: .5;
    cursor: not-allowed;
  }
  
//   input[type="checkbox"]:focus {
//     outline: max(0.15rem, 0.15rem) solid currentColor;
//     outline-offset: max(0.15rem, 0.15rem);
//   }